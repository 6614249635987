import { withJsonFormsMultiEnumProps } from "@jsonforms/react";
import { Dropdown } from "pepsico-ds";
import { useState } from "react";

const MultipleSelectComponent = (props) => {
  const { handleChange, label, path, required, schema, uischema, visible, enabled } =
    props;

  const [selected, setSelected] = useState(
    uischema?.selection === "single" ? "" : []
  );

  const formatData = (data) => (data ? data.map((item) => item.id) : []);

  const handleSelectValue = (val) => {
    setSelected(val);

    handleChange(path, formatData(val));
  };

  return visible ? (
    <div className="multiple-select-wrap">
      <Dropdown
        label={label}
        childList={schema.items.options}
        selection={uischema?.selection === "single" ? "single" : "multiple"}
        isRequired={required}
        disabled={!enabled}
        selectedValue={selected || ""}
        placeholder={uischema.placeholder}
        setSelectedValue={handleSelectValue}
      />
    </div>
  ) : null;
};

export const MultipleSelectControl = withJsonFormsMultiEnumProps(
  MultipleSelectComponent
);
