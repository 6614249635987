import { useMutation, useQuery } from "@apollo/client";
import _ from "lodash";
import { InputItem, Modal, Pagination, ToastContainer } from "pepsico-ds";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { GlobalConfigContext } from "../../context/GlobalConfigContext";
import LoaderContext from "../../context/LoaderContext";
import { deleteRewardPartnerCodeGroup } from "../../graphql/mutations/deleteRewardPartnerCodeGroup";
import listPartnerCodeGroups from "../../graphql/queries/listPartnerCodeGroups";
import { debounceFn } from "../../utils/commons";
import ButtonGroupDynamic from "../common/ButtonGroupDynamic";
import ToastNotification from "../common/toastNotification/ToastNotification";
import JsonFormsWrapper from "../jsonForms/jsonFormsWrapper/JsonFormsWrapper";
import CopyPartnerCodeGroupModel from "./Modals/CopyPartnerCodeGroup/CopyPartnerCodeGroup";
import CreatePartnerCodeGroupModel from "./Modals/CreatePartnerCodeGroup/CreatePartnerCodeGroup";
import EditPartnerCodeGroupModel from "./Modals/EditPartnerCodeGroup/EditPartnerCodeGroup";
import PartnerCodeGroupCard from "./PartnerCodeGroupCard/PartnerCodeGroupCard";
import PartnerCodeGroupTable from "./PartnerCodeGroupTable/PartnerCodeGroupTable";
import getListPartners from "./graphql/queries/listPartners";
import PartnerCodeGroupSummaryModal from "./partnerCodeGroupSummary/partnerCodeGroupSummaryModal";
import "./partners.scss";

const filterJsonSchema = {
  title: "Filter",
  type: "object",
  properties: {
    active: {
      label: "Status",
      items: {
        type: "string",
        options: [
          { id: true, displayText: "Active" },
          { id: false, displayText: "Inactive" },
        ],
      },
    },
    partnerId: {
      label: "Partners",
      items: {
        type: "string",
        options: [{ id: "1234", displayText: "Test" }],
      },
    },
    startDate: {
      type: "string",
    },
    endDate: {
      type: "string",
    },
  },
};

const filterUiSchema1 = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/active",
      component: "multiSelect",
      placeholder: "Status",
      selection: "single",
    },
    {
      type: "Control",
      scope: "#/properties/partnerId",
      component: "multiSelect",
      placeholder: "Partners",
      selection: "single",
    },
    {
      type: "Control",
      scope: "#/properties/startDate",
      component: "dateTimePicker",
      label: "Start date",
    },
    {
      type: "Control",
      scope: "#/properties/endDate",
      component: "dateTimePicker",
      label: "End Date",
      rule: {
        condition: {
          schema: {
            gt: "startDate",
          },
        },
        effect: "VALIDATE",
      },
    },
  ],
};

const PartnerCodeGroupTab = ({
  createPartnerCodeGroupModalOpen,
  toggleCreatePartnerCodeGroupModal,
}) => {
  const [viewType, setViewType] = useState("grid");
  const [mainContentY, setMainContentY] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [numPages, setNumPages] = useState(100);
  const [partnerCodeGroupList, setPartnerCodeGroupList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [filterVar, setFilterVar] = useState({});
  const [searchFilter, setSearchFilter] = useState(null);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [partnerCodeGroupModalOpen, setPartnerCodeGroupModalOpen] = useState(false);
  const [partnerCodeGroupData, setPartnerCodeGroupData] = useState({});
  const [deletePartnerCodeGroupMutation] = useMutation(deleteRewardPartnerCodeGroup);
  const [toastData, setToastData] = useState([]);
  const [filterSchema, setFilterSchema] = useState(filterJsonSchema);
  const [variables, setVariables] = useState({
    size: parseInt(rowsPerPage),
    page: parseInt((currentPage - 1) * rowsPerPage),
    filter: {},
  });
  const { setIsLoading } = useContext(LoaderContext);
  const [selectedPartnerCodeGroup, setSelectedPartnerCodeGroup] = useState({});
  const [copyPartnerCodeGroupModalOpen, setCopyPartnerCodeGroupModalOpen] =
    useState(false);
  const [editPartnerCodeGroupModalOpen, setEditPartnerCodeGroupModalOpen] =
    useState(false);

  const {
    globalState: { programConfig },
  } = useContext(GlobalConfigContext);
  const currentProgramTimezone = programConfig?.localization?.programTimezone;

  const { loading, data, refetch } = useQuery(listPartnerCodeGroups, {
    variables: variables,
    onCompleted: () => {
      setIsLoading(false);
    },
    onError: () => {
      setIsLoading(false);
    },
  });
  const [toast, setToast] = useState({
    open: false,
    type: "",
    title: "",
    description: "",
  });

  // Fetch active partners
  const { data: listOfPartnersData } = useQuery(getListPartners, {
    variables: { filter: { active: true } },
  });

  // Memoize the list of partners to avoid unnecessary re-renders
  const partners = useMemo(() => {
    const list = listOfPartnersData?.listPartners?.items;
    const formattedList = list?.map((option) => ({
      id: option.id,
      displayText: option.name,
    }));
    return formattedList;
  }, [listOfPartnersData]);

  const handleResize = () => {
    setMainContentY(
      Math.ceil(
        document.getElementsByClassName("main-content")[0].getBoundingClientRect()
          .top
      )
    );
  };

  const handleOpenConfirmationModal = () => {
    setConfirmationModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };

  const resetPagination = () => {
    setCurrentPage(1);
    setRowsPerPage(2);
    setNumPages(0);
  };

  const handlePageChange = (newPageIndex) => {
    if (newPageIndex > 0 && newPageIndex <= numPages) {
      setCurrentPage(newPageIndex);
    }
  };

  const handleFilterVariables = (data) => {
    if (Object.keys(data).length > 0) {
      const temp = { ...data };
      if (
        typeof data?.active === "boolean" ||
        (typeof data?.active === "object" && data?.active?.length > 0)
      ) {
        temp.active = data?.active?.length > 0 ? data?.active[0] : data.active;
        setFilterVar({ ...temp });
      } else {
        delete temp.active;
        setFilterVar({ ...temp });
      }
      // Need to remove after multiple selection is implemented
      if (data?.partnerId?.length > 0) {
        temp.partnerId =
          typeof data?.partnerId === "object" && data?.partnerId?.length > 0
            ? data?.partnerId[0]
            : data?.partnerId;
        setFilterVar({ ...temp });
      } else {
        delete temp.partnerId;
        setFilterVar({ ...temp });
      }
      setVariables({ ...variables, filter: temp });
    } else {
      setVariables({ ...variables, filter: {} });
      setFilterVar({});
    }
  };

  const callAfterChange = useCallback(
    debounceFn((searchFilter, filterVar) => {
      if (searchFilter && searchFilter?.length > 0) {
        const temp = { ...filterVar };
        const searchObj = { searchText: searchFilter };
        setFilterVar({ ...temp, ...searchObj });
        handleFilterVariables({ ...temp, ...searchObj });
        resetPagination();
      } else {
        const temp = { ...filterVar };
        delete temp.searchText;
        setFilterVar(temp);
        handleFilterVariables(temp);
      }
    }, 1500),
    []
  );

  const toggleSummaryModal = () => {
    setPartnerCodeGroupModalOpen(!partnerCodeGroupModalOpen);
  };

  const handleDeletePartnerCodeGroup = async () => {
    const val = partnerCodeGroupData;
    try {
      setIsLoading(true);
      const { data } = await deletePartnerCodeGroupMutation({
        variables: { id: val?.id },
      });
      if (data) {
        setToastData([
          {
            id: Date.now(),
            text: "Partner Code Group deleted successfully",
            type: "success",
          },
        ]);
        refetch();
        setIsLoading(false);
      } else {
        console.error("Error deleting Partner Code Group");
        setToastData([
          {
            id: Date.now(),
            text: "Error deleting Partner Code Group",
            type: "error",
          },
        ]);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error deleting Partner Code Group", error);
      setToastData([
        { id: Date.now(), text: "Error deleting Partner Code Group", type: "error" },
      ]);
      setIsLoading(false);
    }
    setConfirmationModalOpen(false);
  };

  const handleActionButtons = (stat, partnerCodeGroup) => {
    if (stat) {
      if (stat == "view") {
        toggleSummaryModal();
        setPartnerCodeGroupData(partnerCodeGroup);
      }
      if (stat == "delete") {
        handleOpenConfirmationModal();
        setPartnerCodeGroupData(partnerCodeGroup);
      }
      if (stat == "copy") {
        setSelectedPartnerCodeGroup(partnerCodeGroup);
        setCopyPartnerCodeGroupModalOpen(true);
      }
      if (stat == "edit") {
        setSelectedPartnerCodeGroup(partnerCodeGroup);
        setEditPartnerCodeGroupModalOpen(true);
      }
    }
  };

  const clearDateRange = () => {
    handleFilterVariables(_.omit(filterVar, ["startDate", "endDate"]));
  };

  const areDateFiltersApplied = (filters) => {
    return filters.startDate || filters.endDate;
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (data?.listPartnerCodeGroups?.items) {
      setPartnerCodeGroupList(data.listPartnerCodeGroups.items);
    }
    if (data?.listPartnerCodeGroups?.total) {
      setTotalCount(data.listPartnerCodeGroups.total);
    }
  }, [data]);

  useEffect(() => {
    if (loading) {
      setIsLoading(true);
    }
  }, [loading]);

  useEffect(() => {
    setNumPages(Math.ceil(totalCount / rowsPerPage));
  }, [totalCount, rowsPerPage]);

  useEffect(() => {
    setVariables({
      ...variables,
      page: parseInt(currentPage - 1),
    });
  }, [currentPage]);

  useEffect(() => {
    refetch();
  }, [variables]);

  useEffect(() => {
    callAfterChange(searchFilter, filterVar);
  }, [searchFilter]);

  useEffect(() => {
    if (partners?.length > 0) {
      const tempSchema = { ...filterSchema };
      tempSchema.properties.partnerId.items.options = partners;
      setFilterSchema(tempSchema);
    }
  }, [partners]);

  return (
    <div className="page-content">
      <div className="view-filter">
        <div className="search-filter-container">
          <div
            style={{
              width: "95%",
              display: "flex",
              alignItems: "flex-end",
              marginBottom: "20px",
            }}
          >
            <InputItem
              placeholder="Type a search keyword..."
              trailingIcon="search"
              size="medium"
              style={{
                width: "30%",
                marginRight: "16px",
              }}
              className="input-search"
              value={searchFilter}
              onChange={(e) => setSearchFilter(e.target.value)}
            />
            <JsonFormsWrapper
              jsonschema={filterSchema}
              uischema={filterUiSchema1}
              data={filterVar}
              onChange={handleFilterVariables}
            />
            {areDateFiltersApplied(filterVar) && (
              <div
                style={{
                  marginLeft: "5px",
                  cursor: "pointer",
                  color: "#007bff",
                  fontSize: "15px",
                  width: "20%",
                  height: "30px",
                }}
                onClick={clearDateRange}
                role="button"
                tabIndex="0"
              >
                Clear Date Range
              </div>
            )}
          </div>
        </div>
        <ButtonGroupDynamic
          items={[
            {
              icon: "view_module",
              onClick: () => {
                setViewType("grid");
              },
            },
            {
              icon: "view_list",
              onClick: () => {
                setViewType("list");
              },
            },
          ]}
          variant="toggle"
          selectedIndex={viewType === "grid" ? 0 : 1}
        />
      </div>
      <div
        className="main-content"
        style={{
          flex: 20,
          overflowY: "auto",
          maxHeight: `calc(100vh - ${mainContentY}px)`,
        }}
      >
        {viewType === "grid" ? (
          <div className="grid-container" style={{ flex: 20, overflowY: "auto" }}>
            {partnerCodeGroupList.map((group, index) => (
              <div className="grid-item grid-item-3 grid-item-4" key={index}>
                <PartnerCodeGroupCard
                  key={index}
                  currentProgramTimezone={currentProgramTimezone}
                  handleActionButtons={handleActionButtons}
                  partnerCodeGroupData={group}
                />
              </div>
            ))}
          </div>
        ) : (
          <PartnerCodeGroupTable
            partnerCodeGroupData={partnerCodeGroupList}
            currentProgramTimezone={currentProgramTimezone}
            handleActionButtons={handleActionButtons}
          />
        )}
        {partnerCodeGroupList?.length === 0 && (
          <p className="no-records">No records found.</p>
        )}
      </div>
      <div
        style={{
          padding: "16px 0px 16px 0px",
          display: "flex", //"flex" Hide the pagination for now. Neet to work on Pagination froe BackEnd & FrontEnd
          justifyContent: "center",
          overflow: "auto",
        }}
      >
        <Pagination
          currentPage={currentPage}
          onUpdate={handlePageChange}
          pageCount={numPages}
          size="medium"
          variant="number"
        />
        {copyPartnerCodeGroupModalOpen && (
          <CopyPartnerCodeGroupModel
            isOpen={copyPartnerCodeGroupModalOpen}
            closeCreatePartnerCodeGroupModal={() =>
              setCopyPartnerCodeGroupModalOpen(false)
            }
            setToast={setToast}
            partnerCodeGroup={selectedPartnerCodeGroup}
            refetch={refetch}
          />
        )}

        {editPartnerCodeGroupModalOpen && (
          <EditPartnerCodeGroupModel
            isOpen={editPartnerCodeGroupModalOpen}
            closeCreatePartnerCodeGroupModal={() =>
              setEditPartnerCodeGroupModalOpen(false)
            }
            setToast={setToast}
            partnerCodeGroup={selectedPartnerCodeGroup}
            refetch={refetch}
          />
        )}
        {partnerCodeGroupModalOpen && (
          <PartnerCodeGroupSummaryModal
            currentProgramTimezone={currentProgramTimezone}
            PartnerCodeGroupSummaryModalOpen={partnerCodeGroupModalOpen}
            toggleSummaryModal={toggleSummaryModal}
            handleActionButtons={handleActionButtons}
            partnerCodeGroupData={partnerCodeGroupData}
          />
        )}
        {createPartnerCodeGroupModalOpen && (
          <CreatePartnerCodeGroupModel
            createPartnerCodeGroupModalOpen={createPartnerCodeGroupModalOpen}
            closeCreatePartnerCodeGroupModal={toggleCreatePartnerCodeGroupModal}
            setToast={setToast}
            refetch={refetch}
          />
        )}

        <ToastNotification
          {...toast}
          handleClose={() => setToast({ ...toast, open: false })}
        />
      </div>
      {toastData && (
        <ToastContainer
          data={toastData}
          removeToast={() => setToastData([])}
          showActionIcon
        />
      )}
      <Modal
        className="confirmation-modal"
        showModal={confirmationModalOpen}
        onCloseModal={handleCloseConfirmationModal}
        primaryButtonProps={{
          size: "medium",
          text: "Delete",
          variant: "primary",
          onClick: handleDeletePartnerCodeGroup,
        }}
        secondaryButtonProps={{
          size: "medium",
          text: "Cancel",
          variant: "secondary",
          onClick: handleCloseConfirmationModal,
        }}
        showTertiaryButton={false}
        showLink={false}
      >
        <p>
          Are you sure you want to delete this partner code group? This action cannot
          be undone.
        </p>
      </Modal>
    </div>
  );
};

export default PartnerCodeGroupTab;
