import { MultiSelectTester, ScopeTester } from "./testers";
// controllers
import { BannerSegmentsControl } from "../ BannerSegmentsController/BannerSegmentsController";
import { CampaignAddContentControl } from "../../campaign/CampaignAddContent/CampaignAddContent";
import { TimeRangeControl } from "../TwoCodeScanPeriod/CustomTimeRangeControl";
import { ArrayLayoutRendererControl } from "../array/ArrayLayout";
import { arrayLayoutTester } from "../array/arrayLayoutTester";
import { ArrayOfFieldsControl } from "../arrayOfFields/ArrayOfFields";
import { BrandSelectControl } from "../brandSelect/BrandSelect";
import { CampaignAddRewardControl } from "../campaignAddRewardField/CampaignAddRewardControl";
import { CampaignSelectControl } from "../campaignSelect/CampaignSelect";
import { CampaignSelectedRewardControl } from "../campaignSelectedRewardField/CampaignSelectedRewardControl";
import { CategorySelectControl } from "../categorySelect/CategorySelect";
import { CheckboxControl } from "../checkboxField/CheckboxControl";
import { CodeGeneratorControl } from "../codeGenerator/CodeGeneratorControl";
import { CodeGroupSelectorControl } from "../codeGroupSelector/CodeGroupSelector";
import { CodeWarningLimitControl } from "../codeWarningLimit/codeWarningLimit";
import { DatePickerControl } from "../datePicker/DatePickerControl";
import { DateTimePickerControl } from "../datePicker/DateTimePickerControl";
import { DatePickerMaterialUIControl } from "../datePickerMaterialUI/DatePickerMaterialUIControl";
import { DaysPickerControl } from "../daysPicker/DaysPickerControl";
import { FileUploadControl } from "../fileUpload/FileUploadController";
import { MultipleDocUploadControl } from "../fileUpload/MultipleDocUploadControl";
import { PartnerFileUploadControl } from "../fileUpload/PartnerFileUploadControl";
import { SingleImageUploadControl } from "../fileUpload/SingleImageUploadControl";
import { FilterSelectControl } from "../filterSelect/FilterSelect";
import { FilterSelectMultipleControl } from "../filterSelect/FilterSelectMultiple";
import { ListDropdownFilterControl } from "../listDropdownFilter/ListDropdownFilterControl";
import { MatchKeywordControl } from "../matchKeywordController/MatchKeywordController";
import { MUISelectControl } from "../muiSelect/MUISelectControl";
import { MultiStepSegmentSelectControl } from "../multiStepSegmentSelect/multiStepSegmentSelect";
import { MultiplePropertiesControl } from "../multiplePropertiesField/MultiplePropertiesControl";
import { MultipleIdSelectorControl } from "../multipleSelect/MultipleIdSelector";
import { MultipleSelectControl } from "../multipleSelect/MultipleSelect";
import { NumberInputControl } from "../numberInputField/NumberInputControl";
import { PartnerSelectControl } from "../partnerSelect/partnerSelect";
import { PreviewCardsCarouselSweepstakesControl } from "../previewWidget/PreviewCardsCarouselSweepstakes/PreviewCardsCarouselSweepstakes";
import { PromoProductSelectorControl } from "../promoProductsSelector/PromoProductSelectorControl";
import { QuizSelectControl } from "../quizSelect/QuizSelect";
import { RadioFieldControl } from "../radioField/RadioControl";
import { RadioInputControl } from "../radioInputField/RadioInputControl";
import { RangeFilterControl } from "../rangeFilter/RangeFilter";
import { RangeSliderControl } from "../rangeSliderMUI/RangeSliderControl";
import { PartnerCodeGroupSelectControl } from "../rewards/partnerCodeGroupSelect/PartnerCodeGroupSelect";
import { RewardUnitSelectControl } from "../rewards/unitSelect/RewardUnitSelect";
import { RichTextEditorControl } from "../richTextEditor/CustomRichTextEditorControl";
import { SearchInputControl } from "../searchInput/SearchInput";
import { CampaignSegmentSelectControl } from "../segmentSelect/CampaignSegmentSelect";
import { SelectControl } from "../select/SelectControl";
import { SimpleTextControl } from "../simpleText/SimpleText";
import { SingleInputArrayControl } from "../singleInputFieldArray/SingleInputArrayControl";
import { MuiSliderControl } from "../slider/MuiSliderControl";
import { SliderControl } from "../slider/SliderControl";
import { SocialMediaSelectorControl } from "../socialMediaSelector/SocialMediaSelector";
import { StringSelectControl } from "../stringSelect/StringSelect";
import { SurveyAndQuizSelectorControl } from "../surveyAndQuizSelector/SurveyAndQuizSelector";
import { SwitchControl } from "../switchField/SwitchControl";
import { SwitchWithModalControl } from "../switchField/SwitchWithModalControl";
import { CampaignTagSelectControl } from "../tagSelect/CampaignTagSelect";
import { EmailDomainsControl } from "../textInputField/EmailDomainsComponent";
import { PrefixAndSuffixInputControl } from "../textInputField/PrefixAndSuffixInputControl";
import { TextInputControl } from "../textInputField/TextInputControl";
import { TextInputHexaColorControl } from "../textInputFieldHexaColor/TextInputFieldHexaColorControl";
import { TextInputArrayControl } from "../textInputFieldsArray/TextInputArrayControl";
import { TextAreaControl } from "../textarea/TextAreaControl";

export const getScopesofAllElements = (schema) => {
  const listOfScopes = [];

  function traverse(obj) {
    if (obj && typeof obj === "object") {
      if (obj.scope) {
        listOfScopes.push({
          scope: obj.scope.split("#")[1],
          component: obj.component ? obj.component : "",
        });
      }
      if (obj.elements && Array.isArray(obj.elements)) {
        obj.elements.forEach((element) => {
          traverse(element);
        });
      }
      if (obj.options?.detail && Array.isArray(obj.options?.detail?.elements)) {
        obj.options.detail.elements.forEach((element) => {
          traverse(element);
        });
      }
    }
  }
  traverse(schema);
  return listOfScopes;
};

export const customerRenderesHelper = (scopeAndCompsList, data) => {
  return scopeAndCompsList?.map(({ component, scope }) => {
    switch (component) {
      case "textInputArray":
        return {
          tester: ScopeTester(scope),
          renderer: TextInputArrayControl,
        };
      case "textarea":
        return { tester: ScopeTester(scope), renderer: TextAreaControl };
      case "file":
        return {
          tester: ScopeTester(scope),
          renderer: FileUploadControl,
        };
      case "singleImageUpload":
        return {
          tester: ScopeTester(scope),
          renderer: SingleImageUploadControl,
        };
      case "multiDocUpload":
        return {
          tester: ScopeTester(scope),
          renderer: MultipleDocUploadControl,
        };
      case "partnerFileUpload":
        return {
          tester: ScopeTester(scope),
          renderer: PartnerFileUploadControl,
        };
      case "select":
        return { tester: ScopeTester(scope), renderer: SelectControl };
      case "checkbox":
        return { tester: ScopeTester(scope), renderer: CheckboxControl };
      case "radio":
        return { tester: ScopeTester(scope), renderer: RadioFieldControl };
      case "toggle":
        return { tester: ScopeTester(scope), renderer: SwitchControl };
      case "switchWithConfirmation":
        return { tester: ScopeTester(scope), renderer: SwitchWithModalControl };
      case "datepicker":
        return { tester: ScopeTester(scope), renderer: DatePickerControl };
      case "muiSelect":
        return {
          tester: ScopeTester(scope),
          renderer: MUISelectControl,
        };
      case "dateTimePicker":
        return {
          tester: ScopeTester(scope),
          renderer: (props) => <DateTimePickerControl {...props} otherData={data} />,
        };
      case "AddRewards":
        return {
          tester: ScopeTester(scope),
          renderer: CampaignAddRewardControl,
        };
      // case "AddSingleReward":
      //   return {
      //     tester: ScopeTester(scope),
      //     renderer: AddSingleRewardControl,
      //   };
      case "campaignSelectedReward":
        return {
          tester: ScopeTester(scope),
          renderer: CampaignSelectedRewardControl,
        };
      case "campaignAddContent":
        return {
          tester: ScopeTester(scope),
          renderer: CampaignAddContentControl,
        };
      case "slider":
        return { tester: ScopeTester(scope), renderer: SliderControl };

      case "rangeFilter":
        return {
          tester: ScopeTester(scope),
          renderer: RangeFilterControl,
        };
      case "richTextEditor":
        return {
          tester: ScopeTester(scope),
          renderer: RichTextEditorControl,
        };
      case "filterSelect":
        return {
          tester: ScopeTester(scope),
          renderer: FilterSelectControl,
        };
      case "multiSelect":
        return {
          tester: MultiSelectTester(scope),
          renderer: MultipleSelectControl,
        };
      case "multipleIdSelector":
        return {
          tester: ScopeTester(scope),
          renderer: MultipleIdSelectorControl,
        };
      case "multiFilterSelect":
        return {
          tester: MultiSelectTester(scope),
          renderer: FilterSelectMultipleControl,
        };
      case "daysPicker":
        return {
          tester: ScopeTester(scope),
          renderer: DaysPickerControl,
        };
      case "datePickerMaterialUI":
        return {
          tester: ScopeTester(scope),
          renderer: DatePickerMaterialUIControl,
        };
      case "numberInput":
        return {
          tester: ScopeTester(scope),
          renderer: NumberInputControl,
        };
      case "textInputHexaColor":
        return {
          tester: ScopeTester(scope),
          renderer: TextInputHexaColorControl,
        };
      case "searchInput":
        return {
          tester: ScopeTester(scope),
          renderer: SearchInputControl,
        };
      case "object":
        return {
          tester: ScopeTester(scope),
          renderer: MultiplePropertiesControl,
        };
      case "previewCardSweepstakes":
        return {
          tester: ScopeTester(scope),
          renderer: PreviewCardsCarouselSweepstakesControl,
        };
      case "campaignTagSelect":
        return {
          tester: ScopeTester(scope),
          renderer: CampaignTagSelectControl,
        };
      case "radioInput":
        return {
          tester: ScopeTester(scope),
          renderer: RadioInputControl,
        };
      case "brandSelect":
        return {
          tester: ScopeTester(scope),
          renderer: BrandSelectControl,
        };
      case "categorySelect":
        return {
          tester: ScopeTester(scope),
          renderer: CategorySelectControl,
        };
      case "Label":
        return {
          tester: ScopeTester(scope),
          renderer: (props) => (
            <span className="$font-xsmall-bold">{props?.uischema?.text}</span>
          ),
        };
      case "segmentSelect":
        return {
          tester: ScopeTester(scope),
          renderer: CampaignSegmentSelectControl,
        };
      case "array":
        return {
          tester: arrayLayoutTester,
          renderer: ArrayLayoutRendererControl,
        };
      case "singleInputArray":
        return {
          tester: ScopeTester(scope),
          renderer: SingleInputArrayControl,
        };
      case "keywordController":
        return {
          tester: ScopeTester(scope),
          renderer: MatchKeywordControl,
        };

      case "bannersegmentselect":
        return {
          tester: ScopeTester(scope),
          renderer: BannerSegmentsControl,
        };
      case "arrayOfTexts":
        return {
          tester: ScopeTester(scope),
          renderer: ArrayOfFieldsControl,
        };
      case "stringSelect":
        return {
          tester: ScopeTester(scope),
          renderer: StringSelectControl,
        };
      case "quizSelect":
        return {
          tester: ScopeTester(scope),
          renderer: QuizSelectControl,
        };
      case "codeGroupSelector":
        return {
          tester: ScopeTester(scope),
          renderer: CodeGroupSelectorControl,
        };
      case "promoProductSelector":
        return {
          tester: ScopeTester(scope),
          renderer: PromoProductSelectorControl,
        };
      case "listDropdownFilter":
        return {
          tester: ScopeTester(scope),
          renderer: ListDropdownFilterControl,
        };
      case "emailDomainsComponent":
        return {
          tester: ScopeTester(scope),
          renderer: EmailDomainsControl,
        };
      case "multiStepSegmentSelect":
        return {
          tester: ScopeTester(scope),
          renderer: MultiStepSegmentSelectControl,
        };
      case "campaignSelect":
        return {
          tester: ScopeTester(scope),
          renderer: CampaignSelectControl,
        };
      case "muiSlider":
        return {
          tester: ScopeTester(scope),
          renderer: MuiSliderControl,
        };
      case "rangeSlider":
        return {
          tester: ScopeTester(scope),
          renderer: RangeSliderControl,
        };
      case "partnerSelect":
        return {
          tester: ScopeTester(scope),
          renderer: PartnerSelectControl,
        };
      case "codeWarningLimit":
        return {
          tester: ScopeTester(scope),
          renderer: CodeWarningLimitControl,
        };
      case "codeGenerator":
        return {
          tester: ScopeTester(scope),
          renderer: CodeGeneratorControl,
        };
      case "customTimeRange":
        return {
          tester: ScopeTester(scope),
          renderer: TimeRangeControl,
        };
      case "prefixSuffixControl":
        return {
          tester: ScopeTester(scope),
          renderer: PrefixAndSuffixInputControl,
        };
      case "rewardUnitSelect":
        return {
          tester: ScopeTester(scope),
          renderer: RewardUnitSelectControl,
        };
      case "partnerCodeGroupSelect":
        return {
          tester: ScopeTester(scope),
          renderer: PartnerCodeGroupSelectControl,
        };
      case "surveyAndQuizSelector":
        return {
          tester: ScopeTester(scope),
          renderer: SurveyAndQuizSelectorControl,
        };
      case "socialMediaSelector":
        return {
          tester: ScopeTester(scope),
          renderer: SocialMediaSelectorControl,
        };
      case "simpleText":
        return {
          tester: ScopeTester(scope),
          renderer: SimpleTextControl,
        };
      default:
        return { tester: ScopeTester(scope), renderer: TextInputControl };
    }
  });
};
