import { useQuery } from "@apollo/client";
import { withJsonFormsControlProps } from "@jsonforms/react";
import CloseIcon from "@mui/icons-material/Close";
import Chip from "@mui/material/Chip";
import { Button, Checkbox, InputItem, Pagination } from "pepsico-ds";
import { useContext, useEffect, useState } from "react";
import { GlobalConfigContext } from "../../../context/GlobalConfigContext";
import { getCodeGroups } from "../../../graphql/queries/codeGroupQueries";
import CodeGroupsTable from "./CodeGroupsTable";
import "./codeGroupsSelector.scss";

const CodeGroupSelectorComponent = ({ data, path, handleChange, uischema }) => {
  const options = uischema.options || {};
  const { programTimezone } = useContext(GlobalConfigContext);
  const [filter, setFilter] = useState({ searchTerm: "", selectAll: false });
  const [selectedCodeGroupList, setSelectedCodeGroupList] = useState([]);
  const [codeGroups, setCodeGroups] = useState([]);
  const initialFilterData = options.initialFilter || {
    lifecycleStatus: "ACTIVE",
  };
  const { data: apiList, loading } = useQuery(getCodeGroups, {
    variables: { filter: initialFilterData },
    fetchPolicy: "cache-first",
  });
  const [pagination, setPagination] = useState({
    recordsPerPage: 5,
    currentPage: 1,
  });

  useEffect(() => {
    if (selectedCodeGroupList?.length === apiList?.listCodeGroups?.length) {
      setFilter((prev) => ({ ...prev, selectAll: true }));
    } else if (selectedCodeGroupList?.length !== apiList?.listCodeGroups?.length) {
      setFilter((prev) => ({ ...prev, selectAll: false }));
    }
  }, [selectedCodeGroupList, apiList]);

  const mapRuleDataToState = () => {
    const formattedSelectedGroups = [];
    if (data?.length && apiList?.listCodeGroups?.length) {
      const formatCodeGroupsById = {};
      apiList?.listCodeGroups?.forEach((keywordGroup) => {
        formatCodeGroupsById[keywordGroup.codeGroupId] = keywordGroup;
      });
      data.forEach((id) => {
        const formattedGroup = formatCodeGroupsById[id];
        if (formattedGroup) {
          formattedSelectedGroups.push(formattedGroup);
        }
      });
      setSelectedCodeGroupList(formattedSelectedGroups);
    }
  };

  const mapStateToRuleData = () => {
    let updatedCodeGroupIds = [];
    if (selectedCodeGroupList.length) {
      updatedCodeGroupIds = selectedCodeGroupList.map(
        (codeGroup) => codeGroup.codeGroupId
      );
    }
    handleChange(path, updatedCodeGroupIds);
  };

  useEffect(() => {
    mapRuleDataToState();
    if (apiList?.listCodeGroups?.length) {
      setCodeGroups(apiList?.listCodeGroups);
    }
  }, [apiList]);

  useEffect(() => {
    mapStateToRuleData();
  }, [selectedCodeGroupList]);

  const onFilterChange = (key, value) => {
    setFilter((prev) => ({ ...prev, [key]: value }));
    const allCodeGroups = JSON.parse(JSON.stringify(apiList?.listCodeGroups));
    if (value === "") {
      setCodeGroups(allCodeGroups);
    } else {
      setCodeGroups(
        allCodeGroups.filter((codeGroup) =>
          codeGroup?.basicSetup?.codeGroupName
            ?.toLowerCase()
            .includes(value.toLowerCase())
        )
      );
    }
    setPagination((prev) => ({ ...prev, currentPage: 1 }));
  };

  const onRemoveCodeGroup = (index) => {
    const newData = selectedCodeGroupList.filter((_, i) => i !== index);
    setSelectedCodeGroupList(newData);
  };
  const onSelectCodeGroup = (codeGroup) => {
    let selectedRows = selectedCodeGroupList
      ? JSON.parse(JSON.stringify(selectedCodeGroupList))
      : [];
    const index = selectedRows?.findIndex(
      (item) => item.codeGroupId === codeGroup.codeGroupId
    );
    if (index > -1) {
      selectedRows = selectedRows?.filter(
        (item) => item.codeGroupId !== codeGroup.codeGroupId
      );
    } else {
      selectedRows = [...selectedRows, codeGroup];
    }
    setSelectedCodeGroupList(selectedRows);
  };

  const handleSelectAll = (value) => {
    if (value) {
      setSelectedCodeGroupList(codeGroups || []);
    } else {
      setSelectedCodeGroupList([]);
    }
  };

  const getPaginatedCodeGroups = () => {
    const start = (pagination.currentPage - 1) * pagination.recordsPerPage;
    const end = start + pagination.recordsPerPage;
    return codeGroups.slice(start, end);
  };

  return (
    <div>
      <div className="display-flex">
        <div style={{ width: "75%" }}>
          <div
            style={{
              padding: "0px",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "16px",
              marginBottom: "0.85rem",
              flex: 0.2,
            }}
          >
            <InputItem
              placeholder="Type a search keyword..."
              trailingIcon="search"
              size="medium"
              value={filter.searchTerm}
              style={{ width: "50%" }}
              className="input-search"
              onChange={(e) => onFilterChange("searchTerm", e.target.value)}
            />
            <Checkbox
              id="select-all"
              text="Select All"
              checked={filter.selectAll}
              onUpdate={handleSelectAll}
            />
          </div>
          {!loading ? (
            <div className="grid-container" style={{ justifyContent: "center" }}>
              <CodeGroupsTable
                codeGroupsData={getPaginatedCodeGroups()}
                selectedCodeGroupsList={selectedCodeGroupList}
                loading={loading}
                programTimezone={programTimezone}
                onSelectCodeGroup={(newCodeGroup) => onSelectCodeGroup(newCodeGroup)}
              />
              {codeGroups.length > 0 && (
                <Pagination
                  currentPage={pagination.currentPage}
                  onUpdate={(page) => {
                    setPagination((prev) => ({ ...prev, currentPage: page }));
                  }}
                  pageCount={Math.ceil(
                    codeGroups.length / pagination.recordsPerPage
                  )}
                  size="medium"
                  variant="number"
                />
              )}
            </div>
          ) : (
            <div>Loading...</div>
          )}
        </div>
        <div
          style={{
            width: "25%",
            padding: "0.5rem",
            backgroundColor: "#F3F7FD",
            marginLeft: "1%",
          }}
        >
          <div className="display-flex justify-content-space-between align-items-center">
            <span style={{ fontWeight: "700", fontSize: "12px" }}>
              {selectedCodeGroupList?.length || 0} selected
            </span>
            <Button
              variant="primaryInverse"
              size="small"
              onClick={() => setSelectedCodeGroupList([])}
              style={{ padding: "0px" }}
            >
              Remove all
            </Button>
          </div>
          <div className="display-flex gap-2 mt-16" style={{ flexWrap: "wrap" }}>
            {selectedCodeGroupList?.map((codeGroup, index) => (
              <Chip
                key={index}
                id={`codeGroup-${codeGroup?.codeGroupId}`}
                className="font-xs"
                label={codeGroup?.basicSetup?.codeGroupName}
                deleteIcon={
                  <CloseIcon
                    sx={{
                      color: "#3A3A3A !important",
                    }}
                  />
                }
                sx={{
                  borderRadius: "8px",
                  fontSize: "12px",
                  backgroundColor: "#B3DACB",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#B3DACB",
                  },
                }}
                onDelete={() => onRemoveCodeGroup(index)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export const CodeGroupSelectorControl = withJsonFormsControlProps(
  CodeGroupSelectorComponent
);
